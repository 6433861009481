
const LandingPage = () => {
  return (
    <iframe 
      src="https://exioslibya.herokuapp.com"
      height="100%"
      width="100%"
    />
  )
}

export default LandingPage;
